import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

const RealStakeHolders = () => {
  const titleRef = useRef(null);
  const contentRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline({ delay: 0.3 });
    tl.fromTo(
      titleRef.current,
      { opacity: 0, y: -50 },
      { opacity: 1, y: 0, duration: 1, ease: 'power3.out' }
    )
      .fromTo(
        contentRef.current,
        { opacity: 0, y: 50 },
        { opacity: 1, y: 0, duration: 1, ease: 'power3.out' },
        '-=0.5'
      )
      .fromTo(
        imageRef.current,
        { opacity: 0, scale: 0.8 },
        { opacity: 1, scale: 1, duration: 1, ease: 'power3.out' },
        '-=0.5'
      );
  }, []);

  return (
    <section className="relative w-full py-20 px-4 lg:px-16">
      {/* Flex container */}
      <div className="flex flex-col lg:flex-row items-center max-w-6xl mx-auto">
        {/* Text Content */}
        <div className="lg:w-1/2 lg:pr-10 mb-8 lg:mb-0 text-center lg:text-left">
          <h2
            ref={titleRef}
            className="text-3xl lg:text-5xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-[#F3C583] to-white"
          >
            Empowering Real Estate Providers & Stakeholders
          </h2>
          <p className='text-white'>
            Property owners, along with key stakeholders like lawyers and bank managers, can now enjoy a hassle-free process for selling or letting properties. With free listings and just a 5% commission if we close the deal, you'll save time, reduce costs, and maximize value. Terms and conditions apply!
          </p>
          <br />

          <div ref={contentRef} className="text-lg lg:text-xl text-gray-700">
            <p className="mb-4 text-white">
              Whether you’re a landlord, property manager, or investor, Asap property is here to maximize returns for you and streamline processes.
            </p>
            <p className="mb-4 text-transparent bg-clip-text bg-gradient-to-r from-[#F3C583] to-white">
              We are dedicated to helping you meet your evolving needs, connect with the right people, and achieve your real estate goals.
            </p>
            <p className="mb-6 text-white"> 
              Together, we create sustainable value for landlords, property owners, and communities.
            </p>
            <a
              href="https://wa.me/2348087789974?text=Hello!%20I%27m%20interested%20in%20learning%20more%20about%20real%20estate%20opportunities%20for%20property%20owners%20and%20stakeholders."
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block px-8 py-4 bg-gradient-to-l  from-[#0a5d49] to-[#F3A229] hover:bg-green-700 text-white text-lg font-medium rounded-full transition-all duration-300"
            >
              Learn More
            </a>
          </div>
        </div>

        {/* Image */}
        <div
          ref={imageRef}
          className="lg:w-2/4 w-[300px] h-auto flex justify-center lg:justify-end"
        >
          <img
            src="/assets/images/duwaa.png" // Replace with your image URL
            alt="Real Estate Providers"
            className="max-w-full h-auto object-cover rounded-lg"
          />
        </div>
      </div>
    </section>
  );
};

export default RealStakeHolders;
