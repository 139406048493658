import { useRef, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const LandPropertySection = () => {
  const carouselRef = useRef(null);
  const [activeCard, setActiveCard] = useState(null); // Track active card for button visibility

  const scrollLeft = () => {
    carouselRef.current.scrollBy({
      left: -300,
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    carouselRef.current.scrollBy({
      left: 300,
      behavior: 'smooth',
    });
  };

  const buttonColors = ['bg-gradient-to-r from-yellow-400 to-yellow-600', 'bg-green-800']; // Alternate colors

  const properties = [
    { image: 'fp6.jpg', price: 'N45,000,000', title: "Luxury In Parakin", ttt: "#Asapproperty", tt: "Newly Built Blocks Of 3 Bedroom Flats", t: "Offering serene views and top-notch amenities", },
    { image: 'fp1.png', price: 'N25,000,000', title: "Quality Meets Quantity", ttt: "#Asapproperty", tttt: "Pitch Your Tent Close To The Banking Area", t: "Nicely built blocks of 2 bedroom & self-contained apartments", location: "At Lagere, Ile Ife" },
    { image: 'fp3.png', price: 'N32,000,000', title: "Live With The Elites", ttt: "#Asapproperty", tt: "Luxury meets serenity", t: "Nothing but quality 2, 3 bedded & self-contained apartments", location: "Parakin Obalufe, Ile Ife" },
    { image: 'fp4.jpg', price: 'N78,000,000', title: "Luxurious Necessities At Mayfair", ttt: "#Asapproperty", ttt: "", location: "Mayfair, Ile Ife" },
    { image: 'fp5.jpeg', price: 'N12,000,000' ,title: "Modern Apartments At Road 7 & Eleyele", ttt: "#Asapproperty", tt: "Reside Within The Central Business District", t: "Contemporary living available in our multiple blocks of flats", location: "Road 7 & Eleyele, Ile Ife"},
  ];

  const handleCardClick = (index) => {
    if (activeCard === index) return; // If already active, do nothing
    setActiveCard(index); // Set the clicked card as active
  };

  return (
    <section className="w-full py-10 px-4 bg-white md:px-8 lg:px-16 py-8 lg:py-8 mb-18">
      <div className="container mx-auto flex flex-col lg:flex-row items-center lg:space-x-8">
        {/* Copy Text */}
        <div className="lg:w-1/2 mb-8 lg:mb-0">
          <h2 className="text-3xl font-bold mb-4 bg-gradient-to-r from-green-800 via-yellow-600 to-yellow-400 bg-clip-text text-transparent">
            Land & Landed Property for Sale
          </h2>
          <p className="text-lg leading-relaxed bg-gradient-to-r from-green-800 to-yellow-600 bg-clip-text text-transparent">
            Explore our premium selection of land and landed properties available for purchase. Ideal for both residential and commercial development, these prime plots offer incredible investment opportunities.
          </p>
        </div>

        {/* Carousel */}
        <div className="lg:w-1/2 relative w-full overflow-hidden">
          {/* Scroll Left */}
          <button
            onClick={scrollLeft}
            className="absolute top-2/3 left-2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-lg hover:bg-gray-700 z-10"
          >
            <FaChevronLeft />
          </button>

          {/* Images */}
          <div
            ref={carouselRef}
            className="flex overflow-x-scroll no-scrollbar space-x-4 snap-x snap-mandatory scroll-smooth"
            style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
          >
            {properties.map((property, idx) => (
              <div
                key={idx}
                onClick={() => handleCardClick(idx)} // Handle card click to activate button
                className="min-w-[250px] md:min-w-[300px] h-48 md:h-64 bg-gray-300 rounded-lg shadow-lg snap-center flex-shrink-0 overflow-hidden relative cursor-pointer"
              >
                <img
                  src={`/assets/images/${property.image}`}
                  alt={`Property ${idx + 1}`}
                  className="w-full h-full object-cover"
                />

                {/* Property Price */}
                <div className='absolute top-4 left-4 bg-black/50 px-2 py-1 rounded'>
                <span className=" text-white font-bold text-lg">
                  {property.price}
                </span>
                    {/* <p className="text-xs sm:text-sm">{property.ttt}</p> */}
                    <p className="text-xs">{property.title}</p>
                    <p className="text-xs">{property.tt}</p>
                    <p className="text-xs">{property.location}</p>
                </div>

                {/* Rent Now Button (only visible if card is active) */}
                {activeCard === idx && (
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-100 transition-opacity duration-300">
                    <a
                      href={`https://wa.me/2348087789974?text=I'm%20interested%20in%20the%20property%20priced%20at%20${property.price}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`absolute bottom-4 left-1/2 transform -translate-x-1/2 ${
                        buttonColors[idx % 2]
                      } text-white px-6 py-2 rounded-full font-semibold shadow-lg hover:brightness-125`}
                    >
                      Buy Now!
                    </a>
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Scroll Right */}
          <button
            onClick={scrollRight}
            className="absolute top-2/3 right-2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-lg hover:bg-gray-700 z-10"
          >
            <FaChevronRight />
          </button>
        </div>
      </div>
    </section>
  );
};

export default LandPropertySection;
