import { useRef, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const ServicedApartmentsSection = () => {
  const carouselRef = useRef(null);
  const [activeCard, setActiveCard] = useState(null); // Track active card for button visibility

  const scrollLeft = () => {
    carouselRef.current.scrollBy({
      left: -300,
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    carouselRef.current.scrollBy({
      left: 300,
      behavior: 'smooth',
    });
  };

  const buttonColors = ['bg-gradient-to-r from-yellow-400 to-yellow-600', 'bg-green-800']; // Alternating button colors

  const properties = [
    { image: 'fp.png', price: 'N25000/day', title: 'Ibadan Road' },
    { image: 'fp1.png', price: 'N30000/day', title: 'Damico area' },
    { image: 'fp3.png', price: 'N25000/day', title: 'Campus gate!' },
    { image: 'buyasap.png', price: 'N22000/day',title: 'Aladanla' },
    { image: 'fp5.jpeg', price: 'N17000/day',title: 'Ife-city' },
  ];

  const handleCardClick = (index) => {
    if (activeCard === index) return; // If already active, do nothing
    setActiveCard(index); // Set clicked card as active
  };

  return (
    <section className="w-full py-10 px-4 bg-white md:px-8 lg:px-16 py-8 lg:py-8 relative -mb-8">
      <div className="container mx-auto flex flex-col lg:flex-row items-center lg:space-x-8">
        {/* Copy Text */}
        <div className="lg:w-1/2 mb-8 lg:mb-0">
          <h2 className="text-3xl font-bold mb-4 bg-gradient-to-r from-green-900 via-yellow-500 to-yellow-300 bg-clip-text text-transparent">
            Serviced Apartments
          </h2>
          <p className="text-lg text-yebogreen">
            Experience luxury living with our fully serviced apartments. Enjoy top-tier amenities, 24/7 concierge services, and modern comforts tailored for a hassle-free lifestyle.
          </p>
        </div>

        {/* Carousel */}
        <div className="lg:w-1/2 relative w-full overflow-hidden">
          {/* Scroll Left */}
          <button
            onClick={scrollLeft}
            className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-lg hover:bg-gray-700 z-10"
          >
            <FaChevronLeft />
          </button>

          {/* Images */}
          <div
            ref={carouselRef}
            className="flex overflow-x-scroll no-scrollbar space-x-4 snap-x snap-mandatory scroll-smooth"
            style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
          >
            {properties.map((property, idx) => (
              <div
                key={idx}
                onClick={() => handleCardClick(idx)} // Handle card click to activate button
                className="min-w-[250px] md:min-w-[300px] h-48 md:h-64 bg-gray-300 rounded-lg shadow-lg snap-center flex-shrink-0 overflow-hidden relative cursor-pointer"
              >
                <img
                  src={`/assets/images/${property.image}`}
                  alt={`Serviced Apartment ${idx + 1}`}
                  className="w-full h-full object-cover"
                />

                {/* Property Price */}
                <div className='absolute top-4 left-4 bg-yebogreen/50 px-2 py-1 rounded'>
                <span className=" text-white font-bold text-lg">
                  {property.price}
                </span>
                <p className='text-sm'>{property.title}</p>
                </div>

                {/* Rent Now Button (only visible if card is active) */}
                {activeCard === idx && (
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-100 transition-opacity duration-300">
                    <a
                      href={`https://wa.me/2348087789974?text=I'm%20interested%20in%20the%20serviced%20apartment%20priced%20at%20${property.price}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`absolute bottom-4 left-1/2 transform -translate-x-1/2 ${
                        buttonColors[idx % 2]
                      } text-white px-6 py-2 rounded-full font-semibold shadow-lg hover:brightness-125`}
                    >
                      Rent Now
                    </a>
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Scroll Right */}
          <button
            onClick={scrollRight}
            className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-lg hover:bg-gray-700 z-10"
          >
            <FaChevronRight />
          </button>
        </div>
      </div>
    </section>
  );
};

export default ServicedApartmentsSection;
