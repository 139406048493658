import { useRef, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const RealSection = () => {
  const carouselRef = useRef(null);
  const [activeCard, setActiveCard] = useState(null); // Track active card for button visibility

  const scrollLeft = () => {
    carouselRef.current.scrollBy({
      left: -300,
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    carouselRef.current.scrollBy({
      left: 300,
      behavior: 'smooth',
    });
  };

  const buttonColors = ['bg-gradient-to-r from-yellow-400 to-yellow-600', 'bg-green-800']; // Alternating button colors

  const properties = [
    { image: 'fp1.png', price: 'N900k/yr', title: "Luxury In Parakin", ttt: "Newly Built Blocks Of 3 Bedroom Flats", t: "Offering serene views and top-notch amenities", location: "Parakin, Ile Ife" },
  { image: 'fp3.png', price: 'N350k/yr', title: "Quality Meets Quantity", tt: "At Lagere" }, 
    { image: 'fp6.jpg', price: 'N500k/yr', title: "Modern Apartments", tt: " At Road 7 & Eleyele" },
    { image: 'fp4.jpg', price: 'N250k/yr', title: "Hospitality close to", tt: "Phase 1 & 2"},
    { image: 'fp5.jpeg', price: 'N200k/yr', title: "Peaceful Living At Igboya" },
  ];

  const handleCardClick = (index) => {
    if (activeCard === index) return; // If already active, do nothing
    setActiveCard(index); // Set clicked card as active
  };

  return (
    <section className="w-full py-10 px-4 bg-white px-4 md:px-8 lg:px-16 py-8 lg:py-8">
      <div className="container mx-auto flex flex-col lg:flex-row items-center lg:space-x-8">
        {/* Copy Text */}
        <div className="lg:w-1/2 mb-8 lg:mb-0">
          <h2 className="text-3xl font-bold mb-4 bg-gradient-to-r from-green-900 via-yellow-500 to-yellow-300 bg-clip-text text-transparent">
            Self Contained Rooms
          </h2>
          <p className="text-lg text-yebogreen leading-relaxed">
            Experience the best in affordable, stylish, and comfortable living with our self-contained rooms. Whether you're a student, professional, or small family, we have the perfect solution for your housing needs.
          </p>
        </div>

        {/* Carousel */}
        <div className="lg:w-1/2 relative w-full overflow-hidden">
          {/* Scroll Left */}
          <button
            onClick={scrollLeft}
            className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-lg hover:bg-gray-700 z-10"
          >
            <FaChevronLeft />
          </button>

          {/* Images */}
          <div
            ref={carouselRef}
            className="flex overflow-x-scroll no-scrollbar space-x-4 snap-x snap-mandatory scroll-smooth"
            style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
          >
            {properties.map((property, idx) => (
              <div
                key={idx}
                onClick={() => handleCardClick(idx)} // Handle card click to activate button
                className="min-w-[250px] md:min-w-[300px] h-48 md:h-64 bg-gray-300 rounded-lg shadow-lg snap-center flex-shrink-0 overflow-hidden relative cursor-pointer"
              >
                <img
                  src={`/assets/images/${property.image}`}
                  alt={`Room ${idx + 1}`}
                  className="w-full h-full object-cover"
                />

                {/* Property Price */}
                <div className="absolute top-4 left-4  bg-yebogreen/50 px-2 py-1 rounded">
                <span className="text-white font-bold text-lg ">
                  {property.price}
                </span>
                <p className='text-xs text-sm:text-sm'>{property.title}</p>
                <p className='text-xs text-sm:text-sm'>{property.tt}</p>
                </div>

                {/* Rent Now Button (only visible if card is active) */}
                {activeCard === idx && (
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-100 transition-opacity duration-300">
                    <a
                      href={`https://wa.me/2348087789974?text=I'm%20interested%20in%20the%20self-contained%20room%20priced%20at%20${property.price}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`absolute bottom-4 left-1/2 transform -translate-x-1/2 ${
                        buttonColors[idx % 2]
                      } text-white px-6 py-2 rounded-full font-semibold shadow-lg hover:brightness-125`}
                    >
                      Rent Now
                    </a>
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Scroll Right */}
          <button
            onClick={scrollRight}
            className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-lg hover:bg-gray-700 z-10"
          >
            <FaChevronRight />
          </button>
        </div>
      </div>
    </section>
  );
};

export default RealSection;
