import React from 'react'
import { footerLinks } from '../constants'

const Footer = () => {
  return (
    <footer className="py-5 sm:px-10 px-5">
      <div className="screen-max-width">
        <div>
          <p className="font-semibold text-yellow text-xs">
            Lease: {' '}
            <span className="underline text-blue">
            Rent {' '}
            </span>
            | {' '}
            <span className="underline text-blue">
            Properrty Management
            </span>{' '}
            <br />
            Afolayan Samuel & Partners
          </p>
          <p className="font-semibold text-yellow text-xs">
            Qualified estate Surveyors & valuers
          </p>
        </div>

        <div className="bg-neutral-700 my-5 h-[1px] w-full" />

        <div className="flex md:flex-row flex-col md:items-center justify-between">
          <p className="font-semibold text-yellow text-xs">Copright @ Asap property 2024. All rights reserved.</p>
          <div className="flex">
            {footerLinks.map((link, i) => (
              <p key={link} className="font-semibold text-yelllow text-xs">
                {link}{' '}
                {i !== footerLinks.length - 1 && (
                  <span className="mx-2"> | </span>
                )}
              </p>
            ))}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer